// Array.from($$('link[hrefLang]')).map((el)=>{return new URL(el.href).hostname})
const validHostnames = [
  "www.tripadvisor.com",
  "www.tripadvisor.co.uk",
  "www.tripadvisor.ca",
  "fr.tripadvisor.ca",
  "www.tripadvisor.it",
  "www.tripadvisor.es",
  "www.tripadvisor.de",
  "www.tripadvisor.fr",
  "www.tripadvisor.jp",
  "cn.tripadvisor.com",
  "www.tripadvisor.in",
  "www.tripadvisor.se",
  "www.tripadvisor.nl",
  "www.tripadvisor.com.br",
  "www.tripadvisor.com.tr",
  "www.tripadvisor.dk",
  "www.tripadvisor.com.mx",
  "www.tripadvisor.ie",
  "ar.tripadvisor.com",
  "www.tripadvisor.com.eg",
  "www.tripadvisor.cz",
  "www.tripadvisor.at",
  "www.tripadvisor.com.gr",
  "www.tripadvisor.com.au",
  "www.tripadvisor.com.my",
  "www.tripadvisor.co.nz",
  "www.tripadvisor.com.ph",
  "www.tripadvisor.com.sg",
  "www.tripadvisor.co.za",
  "www.tripadvisor.com.ar",
  "www.tripadvisor.cl",
  "www.tripadvisor.co",
  "www.tripadvisor.com.pe",
  "www.tripadvisor.com.ve",
  "www.tripadvisor.fi",
  "www.tripadvisor.co.hu",
  "www.tripadvisor.co.id",
  "www.tripadvisor.co.il",
  "www.tripadvisor.co.kr",
  "no.tripadvisor.com",
  "pl.tripadvisor.com",
  "www.tripadvisor.pt",
  "www.tripadvisor.ru",
  "www.tripadvisor.sk",
  "www.tripadvisor.rs",
  "th.tripadvisor.com",
  "www.tripadvisor.com.vn",
  "www.tripadvisor.com.tw",
  "www.tripadvisor.ch",
  "fr.tripadvisor.ch",
  "it.tripadvisor.ch",
  "en.tripadvisor.com.hk",
  "fr.tripadvisor.be",
  "www.tripadvisor.be",
  "www.tripadvisor.com.hk",
];
validHostnames.push("tripadvisor.com");
export { validHostnames };
